import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Settings, User, LogOut, ChevronLeft, ChevronRight, Download, FileText, Home, Menu, X } from 'lucide-react';
import { supabase } from './supabaseClient';
import toast, { Toaster } from 'react-hot-toast';

const Dashboard = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({ store: 'MB Chicken', orderNumber: '', status: '', date: '' });
  const [groupedOrders, setGroupedOrders] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [editedOrder, setEditedOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    fetchOrders();
    toast.success('Welcome to the dashboard!');
  }, []);

  useEffect(() => {
    const grouped = orders.reduce((acc, order) => {
      const date = new Date(order.created_at).toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric', month: 'short' });
      if (!acc[date]) acc[date] = [];
      acc[date].push(order);
      return acc;
    }, {});
    setGroupedOrders(grouped);
  }, [orders]);

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('http://127.0.0.1:8000/receipts', { params: filters });
      setOrders(response.data);
      toast.success(`${response.data.length} orders fetched successfully`);
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast.error("Failed to fetch orders. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOrderClick = async (order) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`http://127.0.0.1:8000/receipts/${order.order_number}`);
      setSelectedOrder(response.data);
      setEditedOrder(response.data);
      setCurrentImageIndex(0);
      setShowModal(true);
      toast.success(`Order #${order.order_number} details loaded`);
    } catch (error) {
      console.error("Error fetching order details:", error);
      toast.error("Failed to load order details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedOrder(null);
    setEditedOrder(null);
    setCurrentImageIndex(0);
  };

  const handleInputChange = (field, value) => {
    setEditedOrder(prev => ({ ...prev, [field]: value }));
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      await axios.put(`http://127.0.0.1:8000/receipts/${selectedOrder.order_number}`, editedOrder);
      setSelectedOrder(editedOrder);
      fetchOrders();
      toast.success("Order updated successfully");
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error("Failed to update order. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const getPresignedUrl = async (fullUrl) => {
    try {
      const response = await fetch(`http://127.0.0.1:8000/api/getPresignedUrl?full_url=${encodeURIComponent(fullUrl)}`);
      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error("Error fetching presigned URL:", error);
      throw error;
    }
  };

  const OrderImage = ({ order }) => {
    const [imageUrl, setImageUrl] = useState('/placeholder.png');

    useEffect(() => {
      const fetchPresignedUrl = async () => {
        if (order.images && order.images.length > 0) {
          const url = await getPresignedUrl(order.images[0].s3_url);
          setImageUrl(url);
        }
      };
      fetchPresignedUrl();
    }, [order]);

    return (
      <img 
        src={imageUrl} 
        alt="Order Preview" 
        className="w-full h-48 object-cover rounded-lg" 
        onError={(e) => { e.target.src = '/placeholder.png'; }}
      />
    );
  };

  const handleFilterChange = (filter, value) => {
    setFilters(prev => ({
      ...prev,
      [filter]: value
    }));
  };

  const handleImageNavigation = (direction) => {
    if (direction === 'next') {
      setCurrentImageIndex(prev => (prev + 1) % selectedOrder.images.length);
    } else {
      setCurrentImageIndex(prev => (prev - 1 + selectedOrder.images.length) % selectedOrder.images.length);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
      toast.success("Logged out successfully");
    } catch (error) {
      console.error("Error during logout:", error.message);
      toast.error("Failed to log out. Please try again.");
    }
  };

  const handleDownload = async (imageIndex = null) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`http://127.0.0.1:8000/download/${selectedOrder.order_number}`);
      if (imageIndex !== null) {
        const link = document.createElement('a');
        link.href = response.data.download_urls[imageIndex];
        link.download = `order_${selectedOrder.order_number}_image_${imageIndex + 1}.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success(`Image ${imageIndex + 1} downloaded successfully`);
      } else {
        response.data.download_urls.forEach((url, index) => {
          const link = document.createElement('a');
          link.href = url;
          link.download = `order_${selectedOrder.order_number}_image_${index + 1}.jpg`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
        toast.success("All images downloaded successfully");
      }
    } catch (error) {
      console.error("Error downloading image(s):", error);
      toast.error("Failed to download image(s). Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100 md:flex-row">
      <Toaster position="top-right" />
      
      {/* Mobile Header */}
      <header className="bg-indigo-900 text-white p-4 flex justify-between items-center md:hidden">
        <img src="/rglogo.png" alt="Rana Group Logo" className="h-8" />
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </header>

      {/* Sidebar */}
      <div className={`${isSidebarOpen ? 'block' : 'hidden'} md:block md:w-64 bg-indigo-900 text-white flex flex-col`}>
        <div className="p-6 flex justify-center md:block">
          <img src="/rglogo.png" alt="Rana Group Logo" className="w-32 md:w-48 h-auto" />
        </div>
        <nav className="flex-grow mt-8">
          <a href="#" className="flex items-center py-3 px-6 bg-indigo-800 hover:bg-indigo-700 transition-colors duration-200">
            <Home className="mr-3" size={20} />
            Dashboard
          </a>
          <a href="/orders" className="flex items-center py-3 px-6 hover:bg-indigo-800 transition-colors duration-200">
            <FileText className="mr-3" size={20} />
            Orders
          </a>
          <a href="/settings" className="flex items-center py-3 px-6 hover:bg-indigo-800 transition-colors duration-200">
            <Settings className="mr-3" size={20} />
            Settings
          </a>
        </nav>
        <div className="p-4">
          <a href="/account" className="flex items-center py-2 px-4 hover:bg-indigo-800 transition-colors duration-200">
            <User className="mr-3" size={20} />
            Account
          </a>
          <button 
            onClick={handleLogout}
            className="w-full flex items-center py-2 px-4 hover:bg-indigo-800 transition-colors duration-200 text-left"
          >
            <LogOut className="mr-3" size={20} />
            Log Out
          </button>
        </div>
      </div>

           {/* Main Content */}
          <div className="flex-1 flex flex-col overflow-hidden">
            <marquee className="bg-yellow-300 text-red-500 font-bold text-xl py-2">
              System in beta. Report any bugs ASAP! Email: <a href="mailto:support@rg-ai.co.uk"  className="underline">support@rg-ai.co.uk</a>
            </marquee>
            <header className="bg-white shadow-sm">
              <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                <h2 className="text-xl font-semibold text-gray-900">Orders</h2>
              </div>
            </header>

        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {/* Filters */}
            <div className="flex flex-col md:flex-row gap-4 mb-6">
              <select
                className="select select-bordered select-sm w-full md:w-auto"
                value={filters.store}
                onChange={(e) => handleFilterChange('store', e.target.value)}
              >
                <option>MB Chicken - Odyssey</option>
                {/* Add other store options here */}
              </select>

              <input
                type="text"
                placeholder="Enter order number"
                className="input input-bordered input-sm w-full md:w-auto"
                value={filters.orderNumber}
                onChange={(e) => handleFilterChange('orderNumber', e.target.value)}
              />

              <select
                className="select select-bordered select-sm w-full md:w-auto"
                value={filters.status}
                onChange={(e) => handleFilterChange('status', e.target.value)}
              >
                <option value="">Status</option>
                <option value="To Review">To Review</option>
                <option value="Disputed">Disputed</option>
                <option value="Successfully Disputed">Successfully Disputed</option>
              </select>

              <input
                type="date"
                className="input input-bordered input-sm w-full md:w-auto"
                value={filters.date}
                onChange={(e) => handleFilterChange('date', e.target.value)}
              />
            </div>

            {/* Orders Grid */}
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
              </div>
            ) : (
              Object.entries(groupedOrders).map(([date, dateOrders]) => (
                <div key={date}>
                  <h3 className="text-lg font-semibold mb-2">{date}</h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-8">
                    {dateOrders.map((order) => (
                      <div
                        key={order.id}
                        className="relative bg-white rounded-lg shadow-md cursor-pointer hover:shadow-xl transition-shadow"
                        onClick={() => handleOrderClick(order)}
                      >
                        <OrderImage order={order} />
                        <div className="absolute top-2 left-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded">
                          {new Date(order.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </div>
                        <div className="absolute top-2 right-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded">
                          #{order.order_number}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        </main>
      </div>

      {/* Order Modal */}
      {selectedOrder && (
        <div className={`fixed inset-0 bg-black bg-opacity-50 ${showModal ? 'flex' : 'hidden'} items-center justify-center p-4`}>
          <div className="bg-white rounded-lg w-full max-w-3xl max-h-full overflow-y-auto">
            <div className="p-4 border-b flex justify-between items-center bg-gray-100">
              <h3 className="text-lg font-semibold">{new Date(selectedOrder.created_at).toLocaleString()} (#{selectedOrder.order_number})</h3>
              <button onClick={handleModalClose} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <div className="p-6">
              <div className="flex flex-col md:flex-row mb-4">
                <div className="w-full md:w-1/2 relative mb-4 md:mb-0">
                  <img 
                    src={selectedOrder.images[currentImageIndex].s3_url} 
                    alt={`Order ${currentImageIndex + 1}`} 
                    className="w-full rounded-lg" 
                  />
                  {selectedOrder.images.length > 1 && (
                    <>
                      <button 
                        onClick={() => handleImageNavigation('prev')} 
                        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1"
                      >
                        <ChevronLeft size={24} />
                      </button>
                      <button 
                        onClick={() => handleImageNavigation('next')} 
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1"
                      >
                        <ChevronRight size={24} />
                      </button>
                    </>
                  )}
                </div>
                <div className="w-full md:w-1/2 md:pl-4">
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Order number</label>
                    <input
                      type="text"
                      className="w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      value={editedOrder.order_number}
                      onChange={(e) => handleInputChange('order_number', e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                    <select
                      className="w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      value={editedOrder.status || 'None'}
                      onChange={(e) => handleInputChange('status', e.target.value)}
                    >
                      <option value="None">None</option>
                      <option value="To Review">To Review</option>
                      <option value="Disputed">Disputed</option>
                      <option value="Successfully Disputed">Successfully Disputed</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Notes</label>
                <textarea
                  className="w-full px-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  rows="3"
                  value={editedOrder.notes}
                  onChange={(e) => handleInputChange('notes', e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="p-4 border-t flex flex-col md:flex-row justify-between bg-gray-100">
              <div className="flex flex-col md:flex-row mb-4 md:mb-0">
                <button
                  className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center justify-center mb-2 md:mb-0 md:mr-2"
                  onClick={() => handleDownload(currentImageIndex)}
                  disabled={isLoading}
                >
                  <Download size={18} className="mr-2" />
                  Download Current
                </button>
                <button
                  className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center justify-center"
                  onClick={() => handleDownload()}
                  disabled={isLoading}
                >
                  <Download size={18} className="mr-2" />
                  Download All
                </button>
              </div>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 w-full md:w-auto"
                onClick={handleSaveChanges}
                disabled={isLoading}
              >
                {isLoading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;