import React, { useState } from 'react';
import { Settings, User, LogOut, FileText, Home, Menu } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from './supabaseClient';
import toast, { Toaster } from 'react-hot-toast';

const SettingsPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
      toast.success("Logged out successfully");
    } catch (error) {
      console.error("Error during logout:", error.message);
      toast.error("Failed to log out. Please try again.");
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100 md:flex-row">
      <Toaster position="top-right" />
      
      {/* Mobile Header */}
      <header className="bg-indigo-900 text-white p-4 flex justify-between items-center md:hidden">
        <img src="/rglogo.png" alt="Rana Group Logo" className="h-8" />
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <Menu size={24} />
        </button>
      </header>

      {/* Sidebar */}
      <div className={`${isSidebarOpen ? 'block' : 'hidden'} md:block md:w-64 bg-indigo-900 text-white flex flex-col`}>
        <div className="p-6 flex justify-center md:block">
          <img src="/rglogo.png" alt="Rana Group Logo" className="w-32 md:w-48 h-auto" />
        </div>
        <nav className="flex-grow mt-8">
          <Link to="/dashboard" className="flex items-center py-3 px-6 hover:bg-indigo-800 transition-colors duration-200">
            <Home className="mr-3" size={20} />
            Dashboard
          </Link>
          <Link to="/orders" className="flex items-center py-3 px-6 hover:bg-indigo-800 transition-colors duration-200">
            <FileText className="mr-3" size={20} />
            Orders
          </Link>
          <Link to="/settings" className="flex items-center py-3 px-6 bg-indigo-800 transition-colors duration-200">
            <Settings className="mr-3" size={20} />
            Settings
          </Link>
        </nav>
        <div className="p-4">
          <Link to="/account" className="flex items-center py-2 px-4 hover:bg-indigo-800 transition-colors duration-200">
            <User className="mr-3" size={20} />
            Account
          </Link>
          <button 
            onClick={handleLogout}
            className="w-full flex items-center py-2 px-4 hover:bg-indigo-800 transition-colors duration-200 text-left"
          >
            <LogOut className="mr-3" size={20} />
            Log Out
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Settings</h1>
            <div className="bg-white shadow-lg rounded-lg p-6 text-center">
              <Settings size={64} className="mx-auto mb-4 text-indigo-600" />
              <h2 className="text-2xl font-semibold mb-2">Coming Soon</h2>
              <p className="text-gray-600">We're working hard to bring you settings. Stay tuned!</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SettingsPage;