import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff, AlertCircle, CheckCircle, Mail, Lock, LogIn } from 'lucide-react';

const CustomAlert = ({ type, message }) => (
  <div className={`alert ${type === 'error' ? 'alert-error' : 'alert-success'} shadow-lg`}>
    <div>
      {type === 'error' ? <AlertCircle className="stroke-current flex-shrink-0 h-6 w-6" /> : <CheckCircle className="stroke-current flex-shrink-0 h-6 w-6" />}
      <span>{message}</span>
    </div>
  </div>
);

const LoginGraphic = () => (
  <img src="/login-graphic.jpg" alt="Login Graphic" className="w-full h-full object-cover rounded-lg shadow-md" />
);

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();

  const showNotification = (message, type) => {
    setNotification({ message, type });
  };

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        throw error;
      } else {
        showNotification('Login successful!', 'success');
        setTimeout(() => navigate('/dashboard'), 1500);
      }
    } catch (error) {
      console.error("Error during login:", error);
      if (error.message.includes('Invalid login credentials')) {
        showNotification('Incorrect email or password. Please try again.', 'error');
      } else {
        showNotification('An unexpected error occurred. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center">
      <div className="card w-full max-w-4xl bg-base-100 shadow-xl">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 p-8 flex flex-col justify-center items-center bg-primary bg-opacity-10 rounded-l-xl">
            <img src="/rgmainlogo.png" alt="RG Logo" className="w-32 h-32 object-contain mb-4" />
            <LoginGraphic />
          </div>
          <div className="divider divider-horizontal"></div>
          <div className="w-full md:w-1/2 card-body">
            <h2 className="card-title justify-center text-3xl font-bold mb-8 text-primary">Welcome Back!</h2>
            <form onSubmit={handleLogin} className="space-y-6">
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-semibold">Email</span>
                </label>
                <div className="relative">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="input input-bordered w-full pl-10"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                </div>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-semibold">Password</span>
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    className="input input-bordered w-full pl-10 pr-10"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                  </button>
                </div>
              </div>
              <div className="form-control mt-6">
                <button 
                  type="submit" 
                  className={`btn btn-primary ${loading ? 'loading' : ''} w-full`}
                  disabled={loading}
                >
                  {loading ? 'Logging in...' : (
                    <>
                      <LogIn size={18} className="mr-2" />
                      Login
                    </>
                  )}
                </button>
              </div>
            </form>
            {notification && (
              <div className="mt-4">
                <CustomAlert type={notification.type} message={notification.message} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;