import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { Calendar, Search, Filter, SortAsc, SortDesc, Check, X, Settings, User, LogOut, FileText, Home, Menu } from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

const AdvancedOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCalendarView, setIsCalendarView] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    applyFiltersAndSort();
  }, [orders, searchTerm, filterStatus, sortField, sortDirection, selectedDate]);

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('http://127.0.0.1:8000/receipts', { params: { 
        store: 'MB Chicken',
        orderNumber: '',
        status: '',
        date: ''
      }});
      setOrders(response.data);
      toast.success(`${response.data.length} orders loaded`);
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Failed to load orders');
    } finally {
      setIsLoading(false);
    }
  };

  const applyFiltersAndSort = () => {
    let result = [...orders];

    // Apply search filter
    if (searchTerm) {
      result = result.filter(order => 
        order.order_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply status filter
    if (filterStatus) {
      result = result.filter(order => order.status === filterStatus);
    }

    // Apply date filter
    if (selectedDate) {
      result = result.filter(order => 
        format(new Date(order.created_at), 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')
      );
    }

    // Apply sorting
    result.sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredOrders(result);
  };

  const handleReview = async (orderId, newStatus) => {
    try {
      const response = await axios.put(`http://127.0.0.1:8000/receipts/${orderId}`, {
        status: newStatus
      });
      toast.success(`Order ${orderId} updated to ${newStatus}`);
      setOrders(orders.map(order => order.id === orderId ? { ...order, status: newStatus } : order));
      setReviewModalOpen(false);
    } catch (error) {
      console.error('Error updating order:', error);
      toast.error('Failed to update order');
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
      toast.success("Logged out successfully");
    } catch (error) {
      console.error("Error during logout:", error.message);
      toast.error("Failed to log out. Please try again.");
    }
  };

  const OrderCard = ({ order }) => (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">Order #{order.order_number}</h2>
        <p>Customer: {order.customer_name}</p>
        <p>Status: <span className={`badge ${getStatusColor(order.status)}`}>{order.status}</span></p>
        <p>Date: {format(new Date(order.created_at), 'PPP')}</p>
        <div className="card-actions justify-end">
          <button className="btn btn-primary" onClick={() => {setSelectedOrder(order); setReviewModalOpen(true);}}>Review</button>
        </div>
      </div>
    </div>
  );

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending': return 'badge-warning';
      case 'Completed': return 'badge-success';
      case 'Cancelled': return 'badge-error';
      default: return 'badge-info';
    }
  };

  const CalendarView = () => (
    <div className="grid grid-cols-7 gap-2">
      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
        <div key={day} className="text-center font-bold">{day}</div>
      ))}
      {filteredOrders.map(order => (
        <div key={order.id} className="p-2 border rounded">
          <p className="text-sm">#{order.order_number}</p>
          <p className="text-xs">{order.customer_name}</p>
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex flex-col h-screen bg-gray-100 md:flex-row">
      <Toaster position="top-right" />
      
      {/* Mobile Header */}
      <header className="bg-indigo-900 text-white p-4 flex justify-between items-center md:hidden">
        <img src="/rglogo.png" alt="Rana Group Logo" className="h-8" />
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <Menu size={24} />
        </button>
      </header>

      {/* Sidebar */}
      <div className={`${isSidebarOpen ? 'block' : 'hidden'} md:block md:w-64 bg-indigo-900 text-white flex flex-col`}>
        <div className="p-6 flex justify-center md:block">
          <img src="/rglogo.png" alt="Rana Group Logo" className="w-32 md:w-48 h-auto" />
        </div>
        <nav className="flex-grow mt-8">
          <Link to="/dashboard" className="flex items-center py-3 px-6 hover:bg-indigo-800 transition-colors duration-200">
            <Home className="mr-3" size={20} />
            Dashboard
          </Link>
          <Link to="/orders" className="flex items-center py-3 px-6 bg-indigo-800 transition-colors duration-200">
            <FileText className="mr-3" size={20} />
            Orders
          </Link>
          <Link to="/settings" className="flex items-center py-3 px-6 hover:bg-indigo-800 transition-colors duration-200">
            <Settings className="mr-3" size={20} />
            Settings
          </Link>
        </nav>
        <div className="p-4">
          <Link to="/account" className="flex items-center py-2 px-4 hover:bg-indigo-800 transition-colors duration-200">
            <User className="mr-3" size={20} />
            Account
          </Link>
          <button 
            onClick={handleLogout}
            className="w-full flex items-center py-2 px-4 hover:bg-indigo-800 transition-colors duration-200 text-left"
          >
            <LogOut className="mr-3" size={20} />
            Log Out
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Advanced Orders Management</h1>
            <p className="text-red-500 font-bold text-4xl bg-yellow-300">Not Working! Don't use</p>
                  {/* Search and Filter Section */}
            <div className="flex flex-wrap gap-4 mb-6">
              <div className="form-control">
                <div className="input-group">
                  <input 
                    type="text" 
                    placeholder="Search orders..." 
                    className="input input-bordered" 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button className="btn btn-square">
                    <Search />
                  </button>
                </div>
              </div>

              <select 
                className="select select-bordered w-full max-w-xs"
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <option value="">All Statuses</option>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
                <option value="Cancelled">Cancelled</option>
              </select>

              <DatePicker 
                selected={selectedDate} 
                onChange={(date) => setSelectedDate(date)} 
                customInput={<button className="btn btn-outline"><Calendar className="mr-2" />Select Date</button>}
              />

              <div className="btn-group">
                <button className={`btn ${sortDirection === 'asc' ? 'btn-active' : ''}`} onClick={() => setSortDirection('asc')}><SortAsc /></button>
                <button className={`btn ${sortDirection === 'desc' ? 'btn-active' : ''}`} onClick={() => setSortDirection('desc')}><SortDesc /></button>
              </div>

              <select 
                className="select select-bordered w-full max-w-xs"
                value={sortField}
                onChange={(e) => setSortField(e.target.value)}
              >
                <option value="created_at">Date</option>
                <option value="order_number">Order Number</option>
                <option value="status">Status</option>
              </select>

              <button className="btn btn-outline" onClick={() => setIsCalendarView(!isCalendarView)}>
                {isCalendarView ? 'Grid View' : 'Calendar View'}
              </button>
            </div>

            {/* Orders Display */}
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
              </div>
            ) : isCalendarView ? (
              <CalendarView />
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredOrders.map(order => (
                  <OrderCard key={order.id} order={order} />
                ))}
              </div>
            )}

            {/* Review Modal */}
            {reviewModalOpen && selectedOrder && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-lg w-full max-w-md p-6">
                  <h2 className="text-2xl font-bold mb-4">Review Order #{selectedOrder.order_number}</h2>
                  <p>Customer: {selectedOrder.customer_name}</p>
                  <p>Current Status: {selectedOrder.status}</p>
                  <div className="mt-4">
                    <button className="btn btn-success mr-2" onClick={() => handleReview(selectedOrder.id, 'Completed')}>
                      <Check size={20} className="mr-2" /> Complete
                    </button>
                    <button className="btn btn-error" onClick={() => handleReview(selectedOrder.id, 'Cancelled')}>
                      <X size={20} className="mr-2" /> Cancel
                    </button>
                  </div>
                  <button className="btn btn-ghost mt-4" onClick={() => setReviewModalOpen(false)}>Close</button>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdvancedOrders;