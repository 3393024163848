import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Login from './Login';
import ProtectedRoute from './ProtectedRoute';
import UserManagement from './UserManagement';
import AdvancedOrders from './AdvancedOrders';
import SettingsPage from './SettingsPage';
import AccountPage from './AccountPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            <ProtectedRoute adminOnly={true}>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsPage/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <AccountPage/>
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Login />} />
        <Route path="/orders" 
        element={
          <ProtectedRoute>
            <AdvancedOrders />
          </ProtectedRoute>
        } />
      </Routes>
    </Router>
  );
}

export default App;