import React, { useState, useEffect } from 'react';
import { Settings, User, LogOut, FileText, Home, Menu, Mail, Lock } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from './supabaseClient';
import toast, { Toaster } from 'react-hot-toast';

const AccountPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();

  const [profile, setProfile] = useState({
    full_name: '',
    email: '',
  });

  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) throw error;
      if (user) {
        setUser(user);
        setProfile({
          full_name: user.user_metadata?.full_name || '',
          email: user.email,
        });
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      toast.error('Failed to load user profile');
    } finally {
      setLoading(false);
    }
  };

  const updateProfile = async () => {
    try {
      setUpdating(true);
      const { data, error } = await supabase.auth.updateUser({
        email: profile.email,
        data: { full_name: profile.full_name }
      });

      if (error) throw error;
      setUser(data.user);
      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(error.message || 'Failed to update profile');
    } finally {
      setUpdating(false);
    }
  };

  const changePassword = async () => {
    try {
      setUpdating(true);
      const { error } = await supabase.auth.updateUser({ password: newPassword });
      if (error) throw error;
      toast.success('Password changed successfully');
      setNewPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      toast.error(error.message || 'Failed to change password');
    } finally {
      setUpdating(false);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
      toast.success("Logged out successfully");
    } catch (error) {
      console.error("Error during logout:", error.message);
      toast.error("Failed to log out. Please try again.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col h-screen bg-gray-100 md:flex-row">
      <Toaster position="top-right" />
      
      {/* Mobile Header */}
      <header className="bg-indigo-900 text-white p-4 flex justify-between items-center md:hidden">
        <img src="/rglogo.png" alt="Rana Group Logo" className="h-8" />
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <Menu size={24} />
        </button>
      </header>

      {/* Sidebar */}
      <div className={`${isSidebarOpen ? 'block' : 'hidden'} md:block md:w-64 bg-indigo-900 text-white flex flex-col`}>
        <div className="p-6 flex justify-center md:block">
          <img src="/rglogo.png" alt="Rana Group Logo" className="w-32 md:w-48 h-auto" />
        </div>
        <nav className="flex-grow mt-8">
          <Link to="/dashboard" className="flex items-center py-3 px-6 hover:bg-indigo-800 transition-colors duration-200">
            <Home className="mr-3" size={20} />
            Dashboard
          </Link>
          <Link to="/orders" className="flex items-center py-3 px-6 hover:bg-indigo-800 transition-colors duration-200">
            <FileText className="mr-3" size={20} />
            Orders
          </Link>
          <Link to="/settings" className="flex items-center py-3 px-6 hover:bg-indigo-800 transition-colors duration-200">
            <Settings className="mr-3" size={20} />
            Settings
          </Link>
        </nav>
        <div className="p-4">
          <Link to="/account" className="flex items-center py-2 px-4 bg-indigo-800 transition-colors duration-200">
            <User className="mr-3" size={20} />
            Account
          </Link>
          <button 
            onClick={handleLogout}
            className="w-full flex items-center py-2 px-4 hover:bg-indigo-800 transition-colors duration-200 text-left"
          >
            <LogOut className="mr-3" size={20} />
            Log Out
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Account Settings</h1>
            
            {/* Name and Email Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
              <h2 className="text-2xl font-semibold mb-4">Change Name or Email</h2>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullName">
                  Full Name
                </label>
                <input
                  id="fullName"
                  type="text"
                  value={profile.full_name}
                  onChange={(e) => setProfile({ ...profile, full_name: e.target.value })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  value={profile.email}
                  onChange={(e) => setProfile({ ...profile, email: e.target.value })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <button 
                onClick={updateProfile}
                disabled={updating}
                className="btn btn-primary"
              >
                {updating ? 'Updating...' : 'Update Profile'}
              </button>
            </div>

            {/* Password Change Section */}
            <div className="bg-white shadow-lg rounded-lg p-6">
              <h2 className="text-2xl font-semibold mb-4">Change Password</h2>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
                  New Password
                </label>
                <input
                  id="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <button 
                onClick={changePassword}
                disabled={updating || !newPassword}
                className="btn btn-primary"
              >
                {updating ? 'Changing...' : 'Change Password'}
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AccountPage;